import Text from "../../text/FormattedText";
import { TextButtonProps, TextTransform } from "../../TYPES";
import ButtonBase from "./ButtonBase";

export default function Button(props: TextButtonProps) {
  return (
    <ButtonBase
      { ...props }
    >
      <Text
        weight={props.weight || 800}
        transform={props.transform || TextTransform.Capitalize}
      >
        {props.children}
      </Text>
    </ButtonBase>
  )
}