import { Box, Image, MantineTheme, Sx } from "@mantine/core";
import AnimatedImage from "./AnimatedImage";

type FloatingImage = {
  src: string,
  left?: number,
  right?: number,
  top?: number,
  bottom?: number,
  small?: boolean,
}

type Props = {
  baseImage: string;
  alt: string;
  floatingImages: FloatingImage[];
}

export default function AnimatedImageGroup(props: Props) {

  // Styles
  const containerStyle: Sx = (theme: MantineTheme) => ({
    width: 250,
    position: 'relative',

    marginTop: 20,
    marginBottom: 20,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });
  const baseImageStyle: Sx = (theme: MantineTheme) => ({
    maxWidth: 250,
  });

  return (
    <Box
      sx={containerStyle}
    >
      <Image
        src={props.baseImage}
        alt={props.alt}
        sx={baseImageStyle}
      />

      {props.floatingImages.map((fI, i) =>
        <Box
          key={i}
          style={{
            position: 'absolute',
            left: fI.left || null,
            right: fI.right || null,
            top: fI.top || null,
            bottom: fI.bottom || null,
            width: '100%',
          }}
        >
          <AnimatedImage
            src={fI.src}
            alt=""
            height={fI.small ? 50 : 80}
            width='auto'
          />
        </Box>
      )}


    </Box>
  )
}