import { useViewportSize } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { MOBILE_BREAKPOINT_PX } from "../../phosphorus/CONSTS";
import Button from "../../phosphorus/inputs/buttons/Button";
import GradientButton from "../../phosphorus/inputs/buttons/GradientButton";
import NavbarBase from "../../phosphorus/layout/NavbarBase";
import { ComponentSize } from "../../phosphorus/TYPES";

import BrandType from "../../assets/images/typeface.png";
import { DEFAULT_GRADIENT } from "../COLORS";
import { useMantineTheme } from "@mantine/core";
import { APP_URL } from "../../func/urlConsts";

type Props = {
  currentPage: 'home' | 'platinum';
}

export default function Navbar(props: Props) {

  // Hooks
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;
  const theme = useMantineTheme();

  return (
    <NavbarBase
    backgroundOnScroll
      background={
        theme.fn.linearGradient(
          45,
          theme.colors.yellow[4],
          theme.colors.salmon[4],
          theme.colors.violet[4]
        )
      }
      favicon={BrandType}
      buttons={[
        // <Button
        //   variant={props.currentPage === 'platinum' ? ButtonVariant.Light : ButtonVariant.Subtle}
        //   key="platinum"
        //   width={isMobile ? '100%' : null}
        //   size={isMobile ? ComponentSize.MD : null}
        // >
        //   {t('navButtons.platinum')}
        // </Button>,

        <GradientButton
          key="app"
          width={isMobile ? '100%' : null}
          size={isMobile ? ComponentSize.MD : null}
          gradient={DEFAULT_GRADIENT}
          withShadow
          component='a'
          href={APP_URL}
        >
          {t('openApp')}
        </GradientButton>
      ]}
    />
  )
}