import { Image } from "@mantine/core";
import { useReducedMotion } from "@mantine/hooks";
import { motion, useMotionValue, useTime } from "framer-motion";
import { CSSProperties, useEffect } from "react";

type Props = {
  src: string;
  alt: string;

  height?: string | number;
  width?: string | number;
  style?: CSSProperties;
}

export default function AnimatedImage(props: Props) {
  const isReducedMotion = useReducedMotion();

  const time = useTime();
  const timeOffset = Math.random() * 10;
  const position = useMotionValue(0);

  useEffect(() => {
    return time.onChange((latest) => {
      position.set(Math.sin(timeOffset + (latest / 500)) * 5);
    })
  }, []);

  return (
    <motion.div
      // whileHover={{ scale: isReducedMotion ? 1 : 1.05 }}
      // whileTap={{ scale: isReducedMotion ? 1 : 1.02 }}
      style={{ y: isReducedMotion ? 0 : position, ...props.style }}
    >
      <Image
        src={props.src}
        alt={props.alt}
        height={props.height}
        width={props.width}
      />
    </motion.div>
  )
}