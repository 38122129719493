// Images
import FullBrand from '../../assets/images/full-brand.png';

import { Image, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import PageSection from "../../phosphorus/layout/sections/PageSection";
import { IS_SITE_URL } from '../../func/urlConsts';

export default function Footer() {

  // Hooks
  const { t } = useTranslation();

  return (
    <PageSection
      backgroundColor='permaBlack'
      style={{ 
        paddingTop: 40, 
        paddingBottom: 40, 
        alignItems: 'center',
        gap: 20,
      }}
    >
      <Image
        src={FullBrand}
        alt="Realmix by Isaac Shea brand"
        height={30}
        width='auto'
      />

      <Text
        component='a'
        href={IS_SITE_URL}
        target='_blank'
        size='sm'
        align='center'
        color='permaWhite'
      >
        {t('footer.text')}
      </Text>
    </PageSection>
  )
}