import { ReactNode } from "react"
import { TextAlign } from "../TYPES";
import { Box, MantineTheme, Sx, Title as BaseTitle, TitleOrder } from "@mantine/core";
import reactStringReplace from "react-string-replace";

type Props = {
  children: ReactNode;

  order?: TitleOrder;
  color?: string;
  size?: number | string;
  align?: TextAlign;

  shadow?: boolean;
}

/** A display title with custom sizing rules, etc. 
 * For semantic headers, use a Header element
 */
export default function DisplayTitle(props: Props) {

  // Styles
  const titleStyle: Sx = (theme: MantineTheme) => ({
    color: props.color ? theme.colors[props.color][4] : theme.black,
    fontFamily: 'Akira, sans-serif',
    lineHeight: 1,
    textShadow: !props.shadow ? null : `0px 10px 20px #00000050`,
  });
  const outlinedStyle: Sx = (theme: MantineTheme) => ({
    WebkitTextStrokeColor: props.color ? theme.colors[props.color][4] : theme.black,
    WebkitTextFillColor: '#00000000',
    WebkitTextStrokeWidth: 2,
  });

  // Format text
  let text: any = props.children;
  text = reactStringReplace(text, /<o>(.*?)<\/o>/, (match, i) => (
    <Box
      component="span"
      sx={outlinedStyle}
      key={i}
    >
      {match}
    </Box>
  ))

  return (
    <BaseTitle
      order={props.order || 1}
      align={props.align}
      size={props.size || 60}
      sx={titleStyle}
    >
      {text}
    </BaseTitle>
  )
}