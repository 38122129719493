import plusJakartaReg from './assets/fonts/PlusJakartaSans-Regular.ttf';
import plusJakartaItalic from './assets/fonts/PlusJakartaSans-Italic.ttf';
import plusJakartaExtraBold from './assets/fonts/PlusJakartaSans-ExtraBold.ttf';
import plusJakartaExtraBoldItalic from './assets/fonts/PlusJakartaSans-ExtraBoldItalic.ttf';
import akiraSuperBold from './assets/fonts/Akira-Super-Bold.otf';

import { useColorScheme } from '@mantine/hooks';
import { Global, MantineProvider } from '@mantine/core';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from './pages/home';
import { BLACK_PALETTE, WHITE_PALETTE } from './components/COLORS';

function App() {

  /** Hooks */
  const colorScheme = useColorScheme();

  return (
    <>
      <Global
        styles={[
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaReg}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaItalic}') format("woff2")`,
              fontWeight: 400,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Plus Jakarta Sans',
              src: `url('${plusJakartaExtraBoldItalic}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'italic',
            },
          },
          {
            '@font-face': {
              fontFamily: 'Akira',
              src: `url('${akiraSuperBold}') format("woff2")`,
              fontWeight: 800,
              fontStyle: 'normal',
            },
          },
        ]}
      />

      <BrowserRouter>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            fontFamily: 'Plus Jakarta Sans, sans-serif',
            headings: { fontFamily: 'Akira, sans-serif', fontWeight: 800 },
            colorScheme: colorScheme,
            colors: {
              salmon: ['#ffe5e7', '#fdb7bc', '#f68991', '#f15b65', '#F6303E', '#d21521', '#a40e19', '#760811', '#480309', '#1e0001'],
              //@ts-ignore
              black: colorScheme === 'light' ? BLACK_PALETTE : WHITE_PALETTE,
              //@ts-ignore
              white: colorScheme === 'light' ? WHITE_PALETTE : BLACK_PALETTE,
              //@ts-ignore
              permaBlack: BLACK_PALETTE,
              //@ts-ignore
              permaWhite: WHITE_PALETTE,
            },
            black: colorScheme === 'light' ? BLACK_PALETTE[0] : WHITE_PALETTE[0],
            white: colorScheme === 'light' ? WHITE_PALETTE[0] : BLACK_PALETTE[0],
            primaryColor: 'permaWhite',
            primaryShade: 4,
            defaultRadius: 'md',
          }}
        >

          {/* Routes */}
          <Routes>
            <Route path='/' element={<HomePage />} />
          </Routes>
        </MantineProvider>
      </BrowserRouter>
    </>
  );
}

export default App;