// Images
import Hero from "../assets/images/hero-image.png";

import { Box, Center, Group } from "@mantine/core";
import { useTranslation } from "react-i18next";
import AnimatedImage from "../components/layout/AnimatedImage";
import Navbar from "../components/layout/Navbar";
import HeroSection from "../phosphorus/layout/sections/HeroSection";
import DisplayTitle from "../phosphorus/text/DisplayTitle";
import { FlexAlign, FlexDirection, FlexJustify, TextAlign } from "../phosphorus/TYPES";
import GradientButton from "../phosphorus/inputs/buttons/GradientButton";
import { DEFAULT_GRADIENT } from "../components/COLORS";
import PageSection from "../phosphorus/layout/sections/PageSection";
import Column from "../phosphorus/layout/columns/ColumnContainer";
import Text from "../phosphorus/text/FormattedText";
import { useViewportSize } from "@mantine/hooks";
import { MOBILE_BREAKPOINT_PX } from "../phosphorus/CONSTS";
import { APP_URL } from "../func/urlConsts";
import { Screenshot1, Screenshot2, Slice1, Slice10, Slice11, Slice12, Slice13, Slice14, Slice2, Slice3, Slice4, Slice5, Slice6, Slice7, Slice8, Slice9 } from "../assets/images/promo";
import AnimatedImageGroup from "../components/layout/AnimatedImageGroup";
import Footer from "../components/layout/Footer";

const 
  SECTION_ONE_IMAGES = [
    { src: Slice7, left: -45, top: 10 },
    { src: Slice4, right: -25, bottom: 1 },
    { src: Slice6, left: 1, top: 90 },
    { src: Slice5, right: -150, bottom: 90, small: true },
  ],
  SECTION_TWO_IMAGES = [
    { src: Slice3, right: -85, top: 1 },
    { src: Slice2, right: -150, bottom: 90 },
    { src: Slice1, left: -45, bottom: 1 },
  ],
  SECTION_THREE_IMAGES = [
    { src: Slice9, left: -45, top: 10 },
    { src: Slice14, right: -25, bottom: 1 },
    { src: Slice8, left: 1, top: 90 },
    { src: Slice13, right: -150, bottom: 90, small: true },
  ]

export default function HomePage() {

  // Hooks
  const { t } = useTranslation('home');
  const { width } = useViewportSize();
  const isMobile = width <= MOBILE_BREAKPOINT_PX;

  return (
    <>
      <Navbar
        currentPage='home'
      />

      {/* Hero */}
      <HeroSection
        backgroundImage={Hero}
        backgroundPosition={{
          desktop: 'center',
        }}
      >
        <DisplayTitle
          size='min(10vw, 70px)'
          color='permaWhite'
          align={TextAlign.Center}
        >
          {t('hero.title')}
        </DisplayTitle>

        <Text
          weight={800}
          color='permaWhite'
          align='center'
        >
          {t('hero.catchphrase')}
        </Text>

        <Center>
          <GradientButton
            gradient={DEFAULT_GRADIENT}
            component='a'
            href={APP_URL}
          >
            {t('openApp', { ns: 'common' })}
          </GradientButton>
        </Center>
      </HeroSection>

      {/* Floating images */}
      <PageSection>
        <Group
          style={{
            alignItems: 'center',
          }}
        >
          <Box style={{
            width: '40%',
            zIndex: 2,
          }}>
            <AnimatedImage
              src={Screenshot1}
              alt="A phone screen displaying a queue for John's Birthday party"
            />
          </Box>
          <Box style={{
            width: '90%',
            position: 'absolute',
            zIndex: 1,
            right: 0,
          }}>
            <AnimatedImage
              src={Screenshot2}
              alt="A tablet screen displaying a queue for John's Birthday party"
            />
          </Box>
        </Group>
      </PageSection>

      {/* Selling points */}
      <PageSection
        backgroundColor='black'
        backgroundOpacity='10'
        style={{ overflow: 'hidden' }}
      >
        {/* Get the party started */}
        <Column.Container
          direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
          align={{ desktop: FlexAlign.Center }}
        >
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Start }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <DisplayTitle
              order={2}
              size={40}
              color='salmon'
            >
              {t('sections.one.title')}
            </DisplayTitle>

            <Text>
              {t('sections.one.text')}
            </Text>
          </Column>
          <Column
            width={{ desktop: '40%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Center }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <AnimatedImageGroup
              baseImage={Slice11}
              alt='An image collage of various elements overlayed onto an image of a party'
              floatingImages={SECTION_ONE_IMAGES}
            />
          </Column>
        </Column.Container>

        {/* Pass the vibe check */}
        <Column.Container
          direction={{ desktop: FlexDirection.RowReverse, mobile: FlexDirection.Column }}
        >
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            align={{ desktop: FlexAlign.End, mobile: FlexAlign.Start }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <DisplayTitle
              order={2}
              size={40}
              color='indigo'
              align={isMobile ? TextAlign.Left : TextAlign.Right}
            >
              {t('sections.two.title')}
            </DisplayTitle>

            <Text
              align={isMobile ? TextAlign.Left : TextAlign.Right}
            >
              {t('sections.two.text')}
            </Text>
          </Column>
          <Column
            width={{ desktop: '40%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Center }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <AnimatedImageGroup
              baseImage={Slice12}
              alt='An image collage of various elements overlayed onto an image of a party'
              floatingImages={SECTION_TWO_IMAGES}
            />
          </Column>
        </Column.Container>

        {/* You want it? We got it */}
        <Column.Container
          direction={{ desktop: FlexDirection.Row, mobile: FlexDirection.Column }}
        >
          <Column
            width={{ desktop: '60%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Start }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <DisplayTitle
              order={2}
              size={40}
              color='orange'
            >
              {t('sections.three.title')}
            </DisplayTitle>

            <Text>
              {t('sections.three.text')}
            </Text>
          </Column>
          <Column
            width={{ desktop: '40%', mobile: '100%' }}
            align={{ desktop: FlexAlign.Center }}
            justify={{ desktop: FlexJustify.Center }}
          >
            <AnimatedImageGroup
              baseImage={Slice10}
              alt='An image collage of various elements overlayed onto an image of a party'
              floatingImages={SECTION_THREE_IMAGES}
            />
          </Column>
        </Column.Container>
      </PageSection>

      <Footer />
    </>
  )
}